$component-name: "c-title";

.#{$component-name} {
  counter-increment: section;

  margin-bottom: rem(25);
  margin-top: 0;

  &::before {
    content: counter(section) ". ";
  }

  &:nth-child(-n + 10)::before {
    content: "0" counter(section) ". ";
  }
}
