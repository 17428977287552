$layout-name: "l-footer";

.#{$layout-name} {
  display: grid;
  height: $global-module-size * 3;
  line-height: 1.35;
  place-content: center;

  &__content {
    margin: 0;
  }

  img {
    margin-left: rem(3);
    max-width: rem(50);
    transform: translateY(rem(6));
  }
}
