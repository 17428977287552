$component-name: "c-info";

.#{$component-name} {
  font-size: 80%;
  margin-top: rem(10);
  opacity: 0.9;

  @include breakpoint(small-up) {
    text-align: right;
  }
}
