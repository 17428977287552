$layout-name: "l-header";

.#{$layout-name} {
  display: grid;
  place-items: center;
  text-align: center;

  h1 {
    font-size: rem(45);
    font-weight: normal;
    line-height: 0.9;
    margin-bottom: 0;
  }

  p {
    color: var(--color-webfatorial-gray-3);
    line-height: 1.25;
    max-width: rem(450);

    &:last-child {
      font-size: rem(12);
      margin-bottom: $global-module-size;
      margin-top: rem(6);
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  span {
    display: block;
    font-size: 2em;
    font-weight: bold;
  }
}
