$component-name: "c-item";

.#{$component-name} {
  display: grid;
  grid-gap: $global-module-size;
  grid-template-columns: repeat(auto-fit, minmax(rem(300), 1fr));

  &__example {
    min-height: 250px;
    position: relative;

    @include breakpoint(small-up) {
      min-height: 400px;
    }

    &-content {
      background: var(--color-webfatorial-white);
      box-shadow: 0 0 ($global-module-size / 2) rem(-9) darken(#3da6c8, 9%);
      height: 100%;
      overflow: hidden;
      position: absolute;
      resize: both;
      width: 100%;
      z-index: z-index(dropdown);
    }
  }

  &__code {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-content {
      overflow-y: auto;
      max-height: rem(195);

      &:first-child {
        margin-bottom: rem(10);
      }
    }
  }
}
