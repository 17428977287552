*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 200dpi),
    only screen and (min-resolution: 1.25dppx) {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

*:focus {
  outline: none;
}

img,
iframe,
object,
embed,
video {
  height: auto;
  max-width: 100%;
}

object,
embed,
video {
  height: auto;
}

figure {
  margin: 0;
}

label {
  cursor: pointer;
}

sup,
sub {
  font-size: 50%;
}

code {
  background-color: #f7f7f9;
  border: rem(1) solid var(--color-webfatorial-gray-5);
  border-radius: rem(3);
  color: #e4405f;
  padding: rem(3) rem(5);
  word-break: break-word;
}

// ==========================================================================
// Project specifics
// ==========================================================================

body {
  counter-reset: section;

  font-family: Nunito, sans-serif;
  font-size: $global-font-size;
}

iframe {
  border: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

section:nth-child(odd) {
  background-color: rgba(61, 166, 200, 0.15);
}

a:not(.link-img) {
  &:link,
  &:visited {
    color: var(--color-dpw-primary);
    padding: 0 rem(2);
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--color-dpw-secondary);
  }
}

// ::selection {
// }

// ::-moz-selection {
// }

// input::placeholder {}
// textarea::placeholder {}
// input::-webkit-input-placeholder {}
// textarea::-webkit-input-placeholder {}
// input::-moz-placeholder {}
// textarea::-moz-placeholder {}
// input:-ms-input-placeholder {}
// textarea:-ms-input-placeholder {}
