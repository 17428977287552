$layout-name: "l-section";

.#{$layout-name} {
  margin: 0 auto;
  max-width: $global-max-width;
  padding: $global-module-size;
  position: relative;

  .c-item {
    margin-top: $global-module-size;
  }
}
