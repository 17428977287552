pre[class*="language-"] {
  margin-bottom: 0;
  margin-top: 0;

  &::before,
  &::after {
    display: none;
  }

  > code {
    border-left: rem(1) solid var(--color-webfatorial-gray-5);
    box-shadow: none;
    padding: 0 rem(5);
  }
}
